<template>
  <div>
    <div class="d-flex justify-end">
      <h1>Saldo: {{ totalBalance }}</h1>
    </div>
    <div>
      <search-client
        class="search-client"
        :searchString="searchString"
        @setSearchString="setSearchString"
        @get-results="setClient"
        @clear-input="clearClient"
      ></search-client>
    </div>
    <v-data-table
      :items="filteredItems"
      :headers="itemHeaders"
      :server-items-length="itemsLength"
      :options.sync="options"
      @update:options="fetchClientsCratesBalance"
    >
    </v-data-table>
  </div>
</template>

<script>
import {
  errorHandler,
  fetchGET,
  successHandler,
} from "../js/functions"
import searchClient from "../components/searchClient.vue"
export default {
  name: "clientsCratesBalance",
  data() {
    return {
      filteredItems: [],
      totalBalance: 0,
      crateModal: false,
      items: [],
      itemsLength: 0,
      itemHeaders: [
        { text: "Klant", value: "client" },
        { text: "Kratten geleverd", value: "totalCrates" },
        { text: "Kratten retour", value: "returnedCrates" },
        { text: "Saldo", value: "cratesBalance" },
      ],
      options: {
        itemsPerPage: 10,
        page: 1,
        sortBy: ["cratesBalance"],
        sortDesc: [true],
      },
      searchString: "",
      selectedClientNumber: null,
    }
  },
  methods: {
    async fetchClientsCratesBalance() {
      try {
        let response = await fetchGET("fetchClientsCratesBalance", { clientNumber: this.selectedClientNumber, options: this.options })
        this.filteredItems = response[0].paginatedResults
        this.totalBalance = response[0].totalBalance[0].totalBalance
        this.itemsLength = response[0].totalCount[0].count
        successHandler("Saldo opgehaald")
      } catch (error) {
        errorHandler(error)
      }
    },
    setSearchString(searchString) {
      this.searchString = searchString
    },
    setClient(client) {
      this.selectedClientNumber = client.clientNumber
      this.fetchClientsCratesBalance()
    },
    clearClient() {
      this.selectedClientNumber = null
      this.searchString = ""
      this.fetchClientsCratesBalance()
    }
  },
  components: {
    searchClient,
  },
}
</script>

<style lang="scss" scoped>
</style>